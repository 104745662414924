<template>
    <div class="forgetPassword">
        <div class="box">
            <!-- 头部 -->
            <div class="header">
                <!-- <img src="../assets/img/logo.png"> -->
                <div class="title">
                    找回密码
                </div>
            </div>
            <!-- 主体内容 -->
            <div class="content">
                <el-form 
                    :model="form" 
                    :rules="formRules" 
                    ref="formRef">
                    <!-- 手机号码 -->
                    <el-form-item 
                        label="请输入手机号码" 
                        prop="phone">
                        <el-input v-model="form.phone"></el-input>
                    </el-form-item>
                    <!-- 验证码 -->
                    <el-form-item 
                        label="请输入验证码" 
                        prop="code" 
                        class="j-f">
                        <el-input class="code-input" v-model="form.code"></el-input>
                        <div class="get-code" 
                            v-if="show" 
                            @click="getCode">
                            获取验证码
                        </div>
                        <div class="get-code" 
                            v-if="!show">   
                            倒数 {{count}} s
                        </div>
                    </el-form-item>
                    <!-- 新密码 -->
                    <el-form-item 
                        label="请输入新密码" 
                        prop="password">
                        <el-input v-model="form.password" :type="inputType?'text':'password'"></el-input>
                        <div class="type-icon">
                            <div>
                                <i v-if="inputType" 
                                    class="icon iconfont icon-yanjing" 
                                    @click="inputType=!inputType">
                                </i>
                                <i v-else 
                                    class="icon iconfont icon-yanjing-bi" 
                                    @click="inputType=!inputType">
                                </i>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
                <button v-if="btnColor" class="btn-color" @click="amendPassword">确认</button>
                <button v-else class="btn" >确认</button>
                <div class="or-box">
                    <span class="or">
                        或
                    </span>
                </div>
                <button class="logn" @click="login">返回登录</button>
            </div>
        </div>
        <!-- 底部内容 -->
        <!-- <div class="pc-footer">
            <img src="@/assets/img/1.png" class="pc-img">
        </div>
        <div class="m-footer">
            <img src="@/assets/img/2.png" class="m-img">
        </div> -->
    </div>
</template>
<script>
import md5 from 'js-md5';
export default {
    data(){
        return{
            form:{
                code:'',
                phone:'',
                password:''
            },
            formRules: {
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入11位数的手机号码', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '请输入6位数的验证码', trigger: 'blur' }
                ],
                password:[
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 18, message: '请输入6字符到18字符的密码', trigger: 'blur' }
                ]
            },
            // 验证码显示状态
            show: true,
            // 倒计时秒数
            count: '',
            // 定时器
            timer: null,
            // type类型
            inputType:false,
        }
    },
    created(){
        this.form.phone=this.$route.query.phone
    },
    computed:{
        btnColor(){
            if(!this.form.phone){
                return false
            }else if(!this.form.code){
                return false
            }else if(!this.form.password){
                return false
            }
            return true
        },
        
    },
    methods:{
        // 验证码倒计时
        async getCode(){
            const {data:res} = await this.$http.get('/verification_code',{
                params:{
                    phone:this.form.phone,
                    sing_code:'WINDATA'
                }
            })
            console.log(res)
            if(res.status!==200) return this.$message({ showClose: true, message: '获取验证码失败，请获取', type: 'error' });
            this.$message({ showClose: true, message: '获取验证码成功，请留意短信', type: 'success' });
            const TIME_COUNT = 60;
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000)
            }
        },
        // 确认修改密码
        amendPassword(){
            this.$refs.formRef.validate(async(valid) => {
                if(!valid) return;
                const {data:res} = await this.$http.put('retrieve_password',{
                    code:this.form.code,
                    phone:this.form.phone,
                    password:md5(this.form.password),
                })
                if(res.status!==200) return this.$message({ showClose: true, message: res.message, type: 'error' });
                this.$message({ showClose: true, message: '修改密码成功', type: 'success' });
                const Data=JSON.parse(window.sessionStorage.getItem('Data'))
                this.$router.push('/verify?app_id='+Data.app_id+'&notify_url='+Data.notify_url)
                // this.$router.push('/verify')
            });
        },
        login(){
            this.$router.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
.j-f{
    display: flex;
}
.forgetPassword{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F6F6;
    flex-direction: column;
    .box{
        width: 24.375rem;
        background-color: #ffffff;
        .header{
            width: 24.375rem;
            height: 3.625rem;
            background: #1A54EC;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.25rem;
            color: #ffffff;
            position: relative;
            img{
                width: 3.625rem;
                height: 3.625rem;
                position: absolute;
                left: 0.625rem;
            }
        }
        .content{
            padding: 20px 1.875rem 3.375rem 1.875rem;
            .el-form{
                margin-bottom: 1.875rem;
                .el-form-item{
                    position: relative;
                    margin-top: 1.875rem;
                    /deep/.el-form-item__label{
                        position: absolute;
                        top: -0.3125rem;
                        left: 0.625rem;
                        z-index: 2;
                        background-color: #ffffff;
                        line-height: 0.75rem;
                        font-size: 0.75rem;
                        padding: 0 0.375rem;
                        height: 0.75rem;
                        font-size: 0.75rem;
                        color: #828494;
                    }
                    /deep/.el-input__inner{
                        height: 2.75rem;
                        // line-height: 2.75rem;
                    }
                    /deep/.el-form-item__content{
                        display: flex;
                        line-height: 0;
                    }
                    .get-code{
                        border: none;
                        color: #FFFFFF;
                        background: #1A54EC;
                        border-radius: 0.125rem;
                        font-size: 0.875rem;
                        width:7rem!important;
                        height: 2.75rem;
                        margin-left:0.625rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .code-input{
                        width: 13rem;
                    }
                    .type-icon{
                        position: absolute;
                        z-index: 10;
                        right: 0.75rem;
                        height: 2.75rem;
                        div{
                            display: flex;
                            align-items: center;
                            height: 100%;
                            .iconfont{
                                font-size: 1.25rem;
                                color: #BABEC7;
                            }
                        }
                    }
                }
            }
            button{
                width: 100%;
                font-size: 0.875rem;
                height: 2.75rem;
            }
            button:hover{
                cursor:pointer;
            }
            .logn{
                color: #828494;
                background: #FFFFFF;
                border: 0.0625rem solid #BABEC7;
                border-radius: 0.125rem;
            }
            .btn{
                background: #BABEC7;
                border-radius: 0.125rem;
                color: #FFFFFF;
                border: none;
            }
            .btn-color{
                background: #1A54EC;
                border-radius: 0.125rem;
                color: #FFFFFF;
                border: none;
            }
            .or-box{
                margin: 1.875rem 0;
                text-align: center;
                color: #828494;
                position: relative;
                span{
                    font-size: 0.875rem;
                }
            }
            .or::before{
                content: '';
                width: 7.125rem;
                height: 0.125rem;
                background-image: linear-gradient(to right, #DCDFE3 , #ffffff);
                position: absolute;
                top: 12px;
                margin-left: 1.875rem;
            }
            .or::after{
                content: '';
                width: 7.125rem;
                height: 0.125rem;
                background-image: linear-gradient(to left, #DCDFE3 , #ffffff);
                position: absolute;
                top: 12px;
                margin-left: -9rem;
            }
        }
    }
    .pc-footer{
        margin: 1.875rem 0;  
        img{
            height: 6.1875rem;
        }
    }
    .m-footer{
        display: none;
    }
}
@media  screen and (max-width:750px ) {
    .forgetPassword{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff!important;
        flex-direction: column;
        padding-top: 58px;
        box-sizing: border-box;
        .box{
            width: 100%;
            background-color: #ffffff;
            .header{
                position: absolute;
                top: 0;
                width: 100%;
                height: 58px;
                background: #1A54EC;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                color: #ffffff;
                img{
                    width: 58px;
                    height: 58px;
                    position: absolute;
                    left: 10px;
                }
            }
            .content{
                padding: 50px 30px 30px 30px;
                .el-form{
                    margin-bottom: 30px;
                    .el-form-item{
                        position: relative;
                        margin-bottom: 0;
                        margin-top: 30px;
                        /deep/.el-form-item__label{
                            position: absolute;
                            top: -5px;
                            left: 10px;
                            z-index: 2;
                            background-color: #ffffff;
                            line-height: 12px;
                            font-size: 12px;
                            padding: 0 6px;
                            height: 12px;
                        }
                        /deep/.el-input__inner{
                            height: 44px;
                            // line-height: 44px;
                        }
                        /deep/.el-form-item__content{
                            display: flex;
                        }
                        .get-code{
                            border: none;
                            color: #FFFFFF;
                            background: #1A54EC;
                            border-radius: 2px;
                            font-size: 14px;
                            width:152px!important;
                            height: 44px;
                            margin-left:10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .code-input{
                            width: 100%;
                        }
                        .type-icon{
                            position: absolute;
                            z-index: 10;
                            right: 12px;
                            height: 44px;
                            div{
                                display: flex;
                                align-items: center;
                                height: 100%;
                                .iconfont{
                                    font-size: 20px;
                                    color: #BABEC7;
                                }
                            }
                        }
                    }
                }
                button{
                    width: 100%;
                    font-size: 14px;
                    height: 44px;
                }
                button:hover{
                    cursor:pointer;
                }
                .logn{
                    color: #828494;
                    background: #FFFFFF;
                    border: 1px solid #BABEC7;
                    border-radius: 2px;
                }
                .btn{
                    background: #BABEC7;
                    border-radius: 2px;
                    color: #FFFFFF;
                    border: none;
                }
                .or-box{
                    text-align: center;
                    color: #828494;
                    margin: 30px 0;
                    position: relative;
                    span{
                        font-size: 14px;
                    }
                }
                .or::before{
                    content: '';
                    width: 114px;
                    height: 2px;
                    background-image: linear-gradient(to right, #DCDFE3 , #ffffff);
                    position: absolute;
                    top: 12px;
                    margin-left: 30px;
                }
                .or::after{
                    content: '';
                    width: 114px;
                    height: 2px;
                    background-image: linear-gradient(to left, #DCDFE3 , #ffffff);
                    position: absolute;
                    top: 12px;
                    margin-left: -144px;
                }
            }
        }
        .pc-footer{
            display: none;
        }
        .m-footer{
            display: block;
            padding: 30px 0; 
        }
    }
}
</style>